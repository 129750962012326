<template>
    <a-modal v-model="thisVisible" :title="'考题详情'" :width="1100" :bodyStyle="{'height':'500px','overflow':'auto'}" :footer="null" @cancel="onClose()">
        <template v-if="question">
            <div class="huiwen-container huiwen-left ">
                <a-tag v-if="question.type == $consts.biz.QUES_TYPES.SINGLE" color="blue" >单选题</a-tag>
                <a-tag v-if="question.type == $consts.biz.QUES_TYPES.MULTI" color="orange" >多选题</a-tag>
                <a-tag v-if="question.type == $consts.biz.QUES_TYPES.JUDGE" color="purple" >判断题</a-tag>
                <a-tag v-if="question.type == $consts.biz.QUES_TYPES.BLANK" color="green" >填空题</a-tag>
                <div class="question-title  huiwen-text-bold huiwen-text-small-title" v-html="question.question"></div>

                <div class="huiwen-container huiwen-left huiwen-margin-top-10">
                    <div class="huiwen-container huiwen-left huiwen-margin-bottom-10" v-for="op in question.options">
                        <template v-if="op.text">
                            <span class="huiwen-text-bold">{{ op.number }}：&nbsp;&nbsp;</span>
                            <span>{{ op.text }}</span>
                        </template>
                    </div>

                    <!--<div class="huiwen-container huiwen-left" style="line-height: 24px">
                        <div class="huiwen-left huiwen-text-bold">选择答案：&nbsp;&nbsp;</div>
                        <div class="huiwen-left">
                            <template v-if="question.type == $consts.biz.QUES_TYPES.SINGLE">
                                <a-radio-group  v-model="question.answerok" disabled>
                                    <a-radio v-for="op2 in question.options" v-if="op2.text" :value="$consts.biz.ANSWER_DICTIONARY[op2.number]">{{ op2.number }}</a-radio>
                                </a-radio-group>
                            </template>
                            <template v-if="question.type == $consts.biz.QUES_TYPES.MULTI" >
                                <a-checkbox-group v-model="question.answerok" disabled>
                                    <a-checkbox v-for="op3 in question.options" v-if="op3.text" :value="$consts.biz.ANSWER_DICTIONARY[op3.number]">{{ op3.number }}</a-checkbox>
                                </a-checkbox-group>

                            </template>
                            <template v-if="question.type == $consts.biz.QUES_TYPES.BLANK">

                            </template>
                        </div>
                        <div class="huiwen-left huiwen-margin-left-10">
                            <a-icon type="close" style="color: #e93d52;"></a-icon>
                        </div>
                    </div>-->
                    <div class="huiwen-container huiwen-left huiwen-margin-top-5">
                        <div class="huiwen-left huiwen-text-bold">正确答案：&nbsp;&nbsp;</div>
                        <div class="huiwen-left huiwen-text-bold huiwen-text-green">{{ question.selectAnswer }}</div>
                    </div>
                    <div class="huiwen-container huiwen-left huiwen-margin-top-5">
                        <div class="huiwen-left huiwen-text-bold">答案解析：&nbsp;&nbsp;</div>
                    </div>
                    <div class="huiwen-container huiwen-left huiwen-margin-top-5">
                        <div class="huiwen-container huiwen-left huiwen-padding-all-10 huiwen-border-box huiwen-bg-gray" v-html="question.answer_comments"></div>
                    </div>
                    <div class="huiwen-container huiwen-left huiwen-margin-top-5">
                        <div class="huiwen-left huiwen-text-bold">我的笔记：&nbsp;&nbsp;</div>
                    </div>
                    <div class="huiwen-container huiwen-left huiwen-margin-top-5">
                        <div class="huiwen-container huiwen-left huiwen-padding-all-10 huiwen-border-box huiwen-bg-gray" v-html="question.comments"></div>
                    </div>
                </div>
            </div>
        </template>

    </a-modal>
</template>

<script>
    import { doQuery, doAction } from "@/api/core"
    export default {
        name: "question-detail",
        props: {
            questionId: String
        },
        watch:{
            'questionId':function(newVal,oldVal) {
                if(newVal && !oldVal) {
                    this.toWrongQuestionDetail(newVal,() => {
                        this.thisVisible = true
                    })
                }
                if(!newVal && oldVal) {
                    this.thisVisible = false
                }
            }
        },
        data() {
            return {
                thisVisible: false,
                question: null,
            }
        },
        methods: {
            onClose() {
                const that = this
                that.$emit('close')
            },
            toWrongQuestionDetail(questionId, func) {
                const that = this
                doQuery({
                    query_code: 'LN030',
                    param_str2: questionId,
                    page: 1,
                    limit: 1
                }).then(res => {
                    if(res.errcode >= that.$consts.biz.OK && res.data && res.data.length > 0) {
                        var ques = res.data[0]
                        var options = [
                            {
                                number: 'A',
                                text: ques.answer1
                            },
                            {
                                number: 'B',
                                text: ques.answer2
                            },
                            {
                                number: 'C',
                                text: ques.answer3
                            },
                            {
                                number: 'D',
                                text: ques.answer4
                            },
                            {
                                number: 'E',
                                text: ques.answer5
                            },
                            {
                                number: 'F',
                                text: ques.answer6
                            },
                        ]
                        ques.options = options
                        var answerok = ''
                        if(ques.answerok) {
                            if(ques.answerok.length == 1) {
                                answerok = that.$consts.biz.ANSWER_DICTIONARY[ques.answerok]
                            }else if(ques.answerok.length > 1) {
                                var arr = ques.answerok.split('|')
                                arr.forEach((a, aIndex) => {
                                    answerok += that.$consts.biz.ANSWER_DICTIONARY[a]
                                    if(aIndex < arr.length - 1) {
                                        answerok = answerok.concat('，')
                                    }
                                })
                            }
                        }

                        ques.selectAnswer = answerok
                        that.question = ques
                        if(func) {
                            func()
                        }
                    }
                })
            },
        }
    }
</script>

<style scoped>
    .question-title {
        text-indent: 62px;
        margin-top: -22px;
        line-height: 22px;
    }
</style>