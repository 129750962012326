import { doQuery } from "@/api/core"
import {mapGetters, mapMutations} from 'vuex'
import QuestionDetail from '@/components/question-detail/question-detail.vue'
export default {
    name: "searchRes",
    data() {
        return {
            searchResults: [],
            searchLoading: false,
            keywords: '',
            questionIdNow: ''
        }
    },
    components: {
        QuestionDetail
    },
    computed: {
        ...mapGetters([
            'searchText',
            'trainingId',
            'trainingVisible',
            'seriesId',
            'seriesVisible',
        ]),
    },
    watch: {
        'searchText': function(newVal, oldVal) {
            this.querySearchRes()
        }
    },
    mounted() {
        const that = this
        if(that.searchText) {
            that.querySearchRes()
        }
    },
    methods: {
        ...mapMutations([
            'SET_TRAINING_ID',
            'SET_TRAINING_VISIBLE',
            'SET_SERIES_ID',
            'SET_SERIES_VISIBLE'
        ]),
        querySearchRes() {
            const that = this
            const { searchText } = that
            that.searchLoading = true
            doQuery({
                query_code: 'LNSRH',
                param_str4: searchText,
                page: 1,
                limit: 1
            }).then(res => {
                if(res.errcode === that.$consts.biz.OK && res.data) {
                    that.searchResults = res.data
                }
            }).finally(() => {
                that.searchLoading = false
            })
        },
        toSingleTask(index,attribute) {
            const that = this
            var task = that.searchResults[index]
            console.log(task)
            //如果是调查问卷
            if (task[attribute] == that.$consts.biz.TASK_TYPES.INVEST) {
                that.$router.push({path: '/student/questionaire?taskId=' + task.task_id })
            }
            //如果是直接考试
            if (task[attribute]  == that.$consts.biz.TASK_TYPES.EXAM) {
                that.$router.push({path: '/student/examination?taskId=' + task.task_id })
            }
            //如果是单次课程培训
            if (task[attribute]  == that.$consts.biz.TASK_TYPES.LESSON || task[attribute]  == that.$consts.biz.TASK_TYPES.SELF) {
                that.$router.push({path: '/student/singleLesson?taskId=' + task.task_id})
            }
            //如果是系列课程培训
            if (task[attribute]  == that.$consts.biz.TASK_TYPES.SERIES) {
                if(that.seriesId && that.seriesId == task.task_id) {
                    that.SET_SERIES_VISIBLE(!that.seriesVisible)
                }else {
                    that.SET_TRAINING_ID(null)
                    that.SET_SERIES_ID(task.task_id)
                }
            }
            //如果是训练营程培训
            if (task[attribute]  == that.$consts.biz.TASK_TYPES.TRAIN) {
                if(that.trainingId && that.trainingId == task.task_id) {
                    that.SET_TRAINING_VISIBLE(!that.trainingVisible)
                }else {
                    that.SET_TRAINING_ID(task.task_id)
                }
            }
        },
        toSingleLesson(index) {
            const that = this
            var task = that.searchResults[index]
            that.$router.push({path: '/student/singleLesson?lessonId=' + task.detail_id})
        },
        toSingleQuestion(index) {
            const that = this
            var task = that.searchResults[index]
            that.questionIdNow = task.detail_id
        }
    }
}
