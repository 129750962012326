import { render, staticRenderFns } from "./searchRes.vue?vue&type=template&id=b900683a&scoped=true&"
import script from "./searchRes.js?vue&type=script&lang=js&"
export * from "./searchRes.js?vue&type=script&lang=js&"
import style0 from "./searchRes.css?vue&type=style&index=0&id=b900683a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b900683a",
  null
  
)

export default component.exports